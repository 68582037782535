'use client';

import { Button, Flex, Text } from '@radix-ui/themes';
import { LinkBreak2Icon } from '@radix-ui/react-icons';

type EmptyPageProps = {
  message: string;
  description?: string;
  reset?: () => void;
  icon?: React.ReactNode;
};

export default function EmptyPage({
  message,
  description,
  reset,
  icon,
}: EmptyPageProps) {
  return (
    <Flex direction="column" flexGrow="1" align="center" justify="center">
      <Flex direction="column" className="space-y-2">
        <Flex
          justify="center"
          align="center"
          direction="column"
          className="space-y-2"
        >
          {icon ?? <LinkBreak2Icon width="40" height="40" />}
          <Text>{message}</Text>
        </Flex>
        {description && <Text size="2">{description}</Text>}
        {reset && <Button onClick={() => reset()}>Try again</Button>}
      </Flex>
    </Flex>
  );
}
